

// Remove no-js class
document.getElementsByTagName('html')[0].classList.remove('no-js')

// Create timeline 
const timeline = document.getElementById("experience-timeline")

if (timeline) {
    const entries = [...timeline.getElementsByTagName('div')]

    for (let i = 0; i < entries.length; i++) {
        const div = entries[i];

        div.classList.add("vtimeline-content")
        const date = div.attributes["data-date"]?.value

        timeline.removeChild(div)
        const wrap = document.createElement('div')
        wrap.className = "vtimeline-point"
        wrap.innerHTML = `
        <div class="vtimeline-icon"><i class="fa fa-map-marker"></i></div>
        <div class="vtimeline-block">
        ${date ? '<span class="vtimeline-date">' + date + '</span>' : ""}
        ${div.outerHTML}
        </div>`

        timeline.appendChild(wrap)
    }
}

// Open mobile menu
document.getElementById("mobile-menu-open").onclick = () => {
    document.querySelector("header")?.classList.add('active')

}

// Close mobile menu
document.getElementById('mobile-menu-close').onclick = () => {
    document.querySelector("header")?.classList.remove('active')
}

// close mobile menu on link click

document.querySelectorAll("#menu a").forEach((link) => {
    link.onclick = () => {
        document.querySelector("header")?.classList.remove('active')
    }
})